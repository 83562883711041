// The values that are defined here are the default values that can
// be overridden by env.js
export class EnvService {
    // General settings
    public availableLocales = ['nl-NL'];
    public defaultLocale = 'nl-NL';
    public companyName = 'SIG';
    public pageTitle = 'SIG Nederland Afbouw & Technische Isolatie';
    public applicationName = 'Mijn SIG';
    public applicationVersion = '1.0';
    public copyright = '&copy; SIG Benelux';
    public countryCode = 'nl';
    public googleTagManagerToken = null;
    public mailchimpToken = null;

    public generalTelephoneNumber = '+31886069600';
    public generalTelephoneNumberLabel = '(088) 60 69 600';

    public generalEmailAddress = 'info@sigbenelux.com';
    public generalEmailAddressLabel = 'info@sigbenelux.com';

    public whatsAppLink = null;
    public facebookLink = 'https://www.facebook.com/siginteriorsnl';
    public instagramLink = 'https://www.instagram.com/sigbenelux/';
    public youtubeLink = 'https://www.youtube.com/channel/UCdynw6F7u5jjSATa5bPQNSw';
    public linkedInLink = 'https://www.linkedin.com/company/sigbenelux';

    public disableLoginForm = false;
    public showAlertAboutNewSystem = false;
    public showRentalService = false;
    public showTechnicalServicesService = false;
    public showSales = false;
    public showAlertAboutHolidays = false;

    public enterpriseSearchAppSearchEnabled = true;
}

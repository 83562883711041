import {Pipe, PipeTransform} from '@angular/core';

@Pipe(
        {
            name: 'interpolate'
        }
)
export class InterpolatePipe implements PipeTransform {
    transform(value: string, replacements: { [key: string]: any }): string {
        for (const key in replacements) {
            if (replacements.hasOwnProperty(key)) {
                value = value.replace(`{{ ${key} }}`, replacements[key]);
            }
        }
        return value;
    }
}

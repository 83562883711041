import {CommonModule} from "@angular/common";
import {LOCALE_ID, ModuleWithProviders, NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {ClampyModule} from "@clampy-js/ngx-clampy";
import {
    NgbDateAdapter,
    NgbDateParserFormatter,
    NgbDatepickerI18n,
    NgbModule,
} from "@ng-bootstrap/ng-bootstrap";
import {ActionReducer} from "@ngrx/store";
import {TranslateModule} from "@ngx-translate/core";
import {DigitOnlyModule} from "@uiowa/digit-only";
import {InlineSVGModule} from "ng-inline-svg";
import {localStorageSync} from "ngrx-store-localstorage";
import {LoggerModule, NgxLoggerLevel} from "ngx-logger";
import {environment} from "src/environments/environment";
import {PageNotFoundComponent} from "../app/components/page-not-found/page-not-found.component";
import {AlertComponent} from "./components/alert/alert.component";
import {FieldValidationComponent} from "./components/field-validation/field-validation.component";
import {CustomDatePipe} from "./pipes/customDate";
import {Nl2brPipe} from "./pipes/nl2br";
import {SortByPipe} from "./pipes/orderBy";
import {SafeHtmlPipe} from "./pipes/safeHtml";
import {AlertService} from "./services/alert.service";
import {SharedApiService} from "./services/api.service";
import {ApiAwareHttpClient} from "./services/api_aware_http_client";
import {AuthService} from "./services/auth.service";
import {
    CustomDateAdapter,
    CustomDateParserFormatter,
} from "./services/datepicker.adapter";
import {FormFieldService} from "./services/form-field.service";
import {FormatterService} from "./services/formatter.service";
import {LocalStorageService} from "./services/localstorage.service";
import {MenuService} from "./services/menu.service";
import {SessionStorageService} from "./services/session.service";
import {UtilService} from "./services/util.service";
import {CustomDatepickerI18n} from "./translate/datepicker-i18n";
import {FilterPipe} from "./pipes/filter";
import {FileService} from "./services/file.service";
import {ProductAdapter} from "../catalogue/adapter/product.adapter";
import {ShoppingCartCountService} from "./services/shopping_cart_count";
import {PartnerAssortment} from "./classes/partner-assortment.class";
import {ArticleAdapter} from "../catalogue/adapter/article.adapter";
import {AuthGuardService} from "./guards/auth-guard.service";
import {RoleGuardService} from "./guards/role-guard.service";
import {SigPartnerAdapter} from "../user/adapter/sig-partner.adapter";
import {ShoppingCartItemAdapter} from "../shopping-cart/adapter/shoppingCartItem.adapter";
import {ArticleImageComponent} from "./components/article-image/article-image.component";
import {ArticleInfoComponent} from "./components/article-info/article-info.component";
import {ProjectAdapter} from "./adapter/project.adapter";
import {ShoppingCartCountAdapter} from "../shopping-cart/adapter/shoppingCartCount.adapter";
import {ArticleUpdateQuantityComponent} from "./components/article-update-quantity/article-update-quantity.component";
import {
    ArticlePricePerPriceUnitComponent
} from "./components/article-price-per-price-unit/article-price-per-price-unit.component";
import {
    ArticlePricePerPriceUnitWrapperComponent
} from "./components/article-price-per-price-unit-wrapper/article-price-per-price-unit-wrapper.component";
import {AddressAdapter} from "./adapter/address.adapter";
import {GenderAdapter} from "./adapter/gender.adapter";
import {OrderAdapter} from "../order/adapter/order.adapter";
import {OrderAddressAdapter} from "../order/adapter/orderAddress.adapter";
import {OrderStatusAdapter} from "../order/adapter/orderStatus.adapter";
import {CarouselAdapter} from "./adapter/carousel.adapter";
import {ArticleDetailsComponent} from "./components/article-details/article-details.component";
import {ImageGalleryComponent} from "./components/image-gallery/image-gallery.component";
import {BrandCarouselComponent} from "./components/brand-carousel/brand-carousel.component";
import {SwiperModule} from "ngx-swiper-wrapper";
import {NewsCarouselComponent} from "./components/news-carousel/news-carousel.component";
import {AboutUsComponent} from "./components/about-us/about-us.component";
import {NearbyComponent} from "./components/nearby/nearby.component";
import {FormLinksComponent} from './components/form-links/form-links.component';
import {FormQuestionComponent} from "./components/form-question/form-question.component";
import {SharedRoutingModule} from "./shared-routing.module";
import {SegmentAdapter} from "./adapter/segment.adapter";
import {NewsItemAdapter} from "../news/adapter/newsItem.adapter";
import {NewsCategoryAdapter} from "../news/adapter/newsCategory.adapter";
import {ViewOrderCodeComponent} from "./components/view-order-code/view-order-code.component";
import {ViewOrderDeliveryComponent} from "./components/view-order-delivery/view-order-delivery.component";
import {InterceptService} from "./services/intercept.service";
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {LoaderComponent} from "./components/loader/loader.component";
import {LoaderService} from "./services/loader.service";
import {RecordResolver} from "./resolvers/record.resolver";
import {RecaptchaFormsModule, RecaptchaModule, RECAPTCHA_LANGUAGE} from "ng-recaptcha";
import {SubSegmentAdapter} from "./adapter/sub-segment.adapter";
import {WarehouseSubSegmentAdapter} from "./adapter/warehouse-sub-segment.adapter";
import {RemoveWhiteSpacesPipe} from "./pipes/removeWhiteSpaces";
import {GoogleMapsModule} from "@angular/google-maps";
import {MapService} from "./services/map.service";
import {WarehouseService} from "./services/warehouse.service";
import {OrderQuantitySelectorComponent} from './components/order-quantity-selector/order-quantity-selector.component';
import {
    ArticleDetailsViewWrapperComponent
} from './components/article-details-view-wrapper/article-details-view-wrapper.component';
import {OrderServiceLineAdapter} from '../order/adapter/orderServiceLine.adapter';
import {SigPartnerResolver} from "./services/sig-partner.resolver";
import {
    ArticlePricePerOrderingUnitComponent,
} from "./components/article-price-per-ordering-unit/article-price-per-ordering-unit.component";
import {
    ArticlePricePerOrderingUnitWrapperComponent
} from "./components/article-price-per-ordering-unit-wrapper/article-price-per-ordering-unit-wrapper.component";
import {
    BackgroundImageCarouselComponent
} from "./components/background-image-carousel/background-image-carousel.component";
import {
    NoPartnerOrganisationModalComponent
} from "./components/no-partner-organisation-modal/no-partner-organisation-modal.component";
import {WarehousesResolver} from "./resolvers/warehouses.resolver";
import {EnvServiceProvider} from './services/env.service.provider';
import {VideoModalComponent} from "./components/video-modal/video-modal.component";
import {ArticlePriceTotalComponent} from "./components/article-price-total/article-price-total.component";
import {
    ArticlePriceTotalViewWrapperComponent
} from "./components/article-price-total-view-wrapper/article-price-total-view-wrapper.component";
import {
    ArticlePriceTotalWrapperComponent
} from "./components/article-price-total-wrapper/article-price-total-wrapper.component";
import {
    ArticlePricePerOrderingUnitViewWrapperComponent
} from "./components/article-price-per-ordering-unit-view-wrapper/article-price-per-ordering-unit-view-wrapper.component";
import {
    ArticlePricePerPriceUnitViewWrapperComponent
} from "./components/article-price-per-price-unit-view-wrapper/article-price-per-price-unit-view-wrapper.component";
import {AlertAboutNewSystemComponent} from "./components/alert-about-new-system/alert-about-new-system.component";
import {AlertAdapter} from "../app/components/adapter/alert.adapter";
import {UcWordsPipe} from "./pipes/ucWords";
import {
    AppSearchQuerySuggestionsComponent
} from "./components/app-search-query-suggestions/app-search-query-suggestions.component";
import {AppSearchAdapter} from "../catalogue/adapter/app-search.adapter";
import {InterpolatePipe} from "./pipes/interpolate";

export function localStorageSyncReducer(
        reducer: ActionReducer<any>
): ActionReducer<any> {
    return localStorageSync({
        keys: ["auth"],
        rehydrate: true,
        storage: sessionStorage,
        storageKeySerializer: btoa,
    })(reducer);
}

@NgModule({
    declarations: [
        AlertComponent,
        ArticleImageComponent,
        ArticleInfoComponent,
        ArticleDetailsComponent,
        ArticleUpdateQuantityComponent,
        ArticlePricePerPriceUnitComponent,
        ArticlePricePerPriceUnitWrapperComponent,
        ArticlePricePerPriceUnitViewWrapperComponent,
        SafeHtmlPipe,
        RemoveWhiteSpacesPipe,
        InterpolatePipe,
        SortByPipe,
        FilterPipe,
        CustomDatePipe,
        Nl2brPipe,
        PageNotFoundComponent,
        FieldValidationComponent,
        ImageGalleryComponent,
        BrandCarouselComponent,
        NewsCarouselComponent,
        AboutUsComponent,
        NearbyComponent,
        FormLinksComponent,
        FormQuestionComponent,
        ViewOrderCodeComponent,
        ViewOrderDeliveryComponent,
        LoaderComponent,
        OrderQuantitySelectorComponent,
        ArticleDetailsViewWrapperComponent,
        ArticlePricePerOrderingUnitComponent,
        ArticlePricePerOrderingUnitWrapperComponent,
        ArticlePricePerOrderingUnitViewWrapperComponent,
        BackgroundImageCarouselComponent,
        NoPartnerOrganisationModalComponent,
        VideoModalComponent,
        ArticlePriceTotalComponent,
        ArticlePriceTotalViewWrapperComponent,
        ArticlePriceTotalWrapperComponent,
        AlertAboutNewSystemComponent,
        UcWordsPipe,
        AppSearchQuerySuggestionsComponent
    ],
    providers: [
        EnvServiceProvider,
        {provide: NgbDateAdapter, useClass: CustomDateAdapter},
        {
            provide: NgbDateParserFormatter,
            useClass: CustomDateParserFormatter,
        },
        {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
        {provide: LOCALE_ID, useValue: "nl-nl"},
        {provide: "DATE_FORMAT", useValue: "DD-MM-YYYY"},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptService,
            multi: true
        },
        {
            provide: RECAPTCHA_LANGUAGE,
            useValue: localStorage.getItem('locale') ? localStorage.getItem('locale') : window['__env'].defaultLocale
        },
        SigPartnerResolver,
        LoaderService
    ],
    imports: [
        SharedRoutingModule,
        RouterModule,
        NgbModule,
        CommonModule,
        FormsModule,
        LoggerModule.forRoot({
            serverLoggingUrl: "/api/logs",
            level: environment.production
                    ? NgxLoggerLevel.ERROR
                    : NgxLoggerLevel.DEBUG,
            serverLogLevel: NgxLoggerLevel.OFF,
            enableSourceMaps: false,
        }),
        InlineSVGModule.forRoot(),
        ClampyModule,
        TranslateModule,
        DigitOnlyModule,
        SwiperModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        GoogleMapsModule,
    ],
    entryComponents: [
        AlertComponent,
        NoPartnerOrganisationModalComponent,
        VideoModalComponent
    ],
    exports: [
        ArticleImageComponent,
        ArticleInfoComponent,
        ArticleDetailsComponent,
        ArticleUpdateQuantityComponent,
        ArticlePricePerPriceUnitComponent,
        ArticlePricePerPriceUnitWrapperComponent,
        ArticlePricePerPriceUnitViewWrapperComponent,
        SafeHtmlPipe,
        RemoveWhiteSpacesPipe,
        InterpolatePipe,
        SortByPipe,
        FilterPipe,
        CustomDatePipe,
        Nl2brPipe,
        TranslateModule,
        CommonModule,
        FormsModule,
        DigitOnlyModule,
        FieldValidationComponent,
        ImageGalleryComponent,
        BrandCarouselComponent,
        NewsCarouselComponent,
        AboutUsComponent,
        NearbyComponent,
        FormLinksComponent,
        FormQuestionComponent,
        ViewOrderCodeComponent,
        ViewOrderDeliveryComponent,
        LoaderComponent,
        OrderQuantitySelectorComponent,
        ArticlePricePerOrderingUnitComponent,
        ArticlePricePerOrderingUnitWrapperComponent,
        ArticlePricePerOrderingUnitViewWrapperComponent,
        BackgroundImageCarouselComponent,
        ArticlePriceTotalComponent,
        ArticlePriceTotalViewWrapperComponent,
        ArticlePriceTotalWrapperComponent,
        AlertAboutNewSystemComponent,
        UcWordsPipe,
        AppSearchQuerySuggestionsComponent
    ],
})
export class SharedModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [
                ApiAwareHttpClient,
                FileService,
                LocalStorageService,
                SessionStorageService,
                FormatterService,
                AlertService,
                ShoppingCartCountService,
                SharedApiService,
                ProductAdapter,
                ArticleAdapter,
                SigPartnerAdapter,
                ShoppingCartItemAdapter,
                ShoppingCartCountAdapter,
                ProjectAdapter,
                AddressAdapter,
                GenderAdapter,
                MenuService,
                AuthService,
                AuthGuardService,
                RoleGuardService,
                UtilService,
                PartnerAssortment,
                FormFieldService,
                OrderAdapter,
                OrderAddressAdapter,
                OrderStatusAdapter,
                CarouselAdapter,
                NewsItemAdapter,
                NewsCategoryAdapter,
                SegmentAdapter,
                RecordResolver,
                SubSegmentAdapter,
                WarehouseSubSegmentAdapter,
                MapService,
                WarehouseService,
                OrderServiceLineAdapter,
                WarehousesResolver,
                AlertAdapter,
                AppSearchAdapter
            ],
        };
    }
}
